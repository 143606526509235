<script>
    import Aws from "@/services/Aws";
    import Swal from "sweetalert2";

    export default {
        props: {
            TargetGroupArn: String,
            minRegisteredInstances: String
        },
        data() {
            return {
                error: null,
                tableData: [],
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "InstanceName",
                sortDesc: false,
                isBusy: false,
                filterInstanceStatus: 0,
                fields: [
                    {
                        key: "InstanceId",
                        label: "Instance Id",
                        sortable: false,
                    },
                    {
                        key: "InstanceName",
                        label: "Instance Name",
                        sortable: true,
                    },
                    {
                        key: "Port",
                        label: "Port",
                        sortable: false,
                    },
                    {
                        key: "State",
                        label: "State",
                        sortable: false,
                    },
                    "action",
                ],
                totalRows: 0,
                showModal: false
            };
        },
        async mounted() {
            await this.getTargetGroupInstances()
        },
        methods: {
            async getTargetGroupInstances() {
                try {
                    this.isBusy = true;
                    const response = await Aws.getTargetGroupInstances(this.TargetGroupArn);
                    this.originalTableData = response.data.data.instances;
                   /* let newInstanceData = {
                        "InstanceId": "i-0e0aa560820d52b4e",
                        "Port": 443,
                        "HealthCheckPort": "80",
                        "State": "running",
                        "InstanceName": "API 3.3.0 stable 6",
                        "AllowInstanceActions": 1
                    };
                    this.originalTableData.push(newInstanceData);*/
                    this.$emit('setRegisteredTargetGroupInstances', this.originalTableData)
                    this.tableData = [...this.originalTableData];
                    this.totalRows = this.tableData.length;
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = [];
                    this.totalRows = 0;
                } finally {
                    this.showLoader = false;
                    this.isBusy = false;
                }
            },

            deregisterInstanceTargetGroup(instance){
                Swal.fire({
                    title: "Deregister Instance <br>" + instance.InstanceName,
                    icon: "warning",
                    html: `
                        <p>Type "Deregister" to confirm:</p>
                        <input type="text" id="deregisterConfirmation" class="swal2-input" autocomplete="off">
                    `,
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Deregister",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        const confirmationText = Swal.getPopup().querySelector(
                            "#deregisterConfirmation"
                        ).value;
                        if (confirmationText.toLowerCase() !== "deregister") {
                            Swal.showValidationMessage('You must type "Deregister" to confirm');
                            return false;
                        }
                        return Aws.deregisterInstanceToTargetGroup({
                            InstanceId: instance.InstanceId,
                            TargetGroupArn: this.$route.params.id,
                            csrf_token: this.csrf_token
                        })
                            .then((response) => {
                                return response;
                            })
                            .catch((error) => {
                                let errorMsg = error.response.data.error
                                    ? error.response.data.error
                                    : "";
                                this.failedmsg(errorMsg);
                            });
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        const res = result.value.data ? result.value.data.success : false;
                        const error = result.value.data.error
                            ? result.value.data.error
                            : "Failed";

                        if (res) {
                            this.successmsg('Deregister Instance', 'Instance is successfully deregistered')
                            setTimeout(() => {
                                this.getTargetGroupInstances();
                            }, 2000);
                        } else {
                            this.warningmsg(error);
                        }
                    }
                });
            },

            onFiltered(filteredItems) {
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
        middleware: "authentication",
    };
</script>
<template>
    <div class="row">
        <div class="row">
            <div class="col-sm-12 col-md-3">
                <div class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                                v-model="perPage"
                                size="sm"
                                :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                    </label>
                </div>
            </div>
        </div>

        <!-- Table -->
        <div class="table-responsive mb-0">
            <b-table
                    :busy="isBusy"
                    :items="tableData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    show-empty
                    empty-text="No Data Found"
                    @filtered="onFiltered"
            >

                <template v-slot:cell(InstanceName)="data">
                   {{data.item.InstanceName}}
                </template>

                <template v-slot:cell(action)="data">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <router-link title="View Instance" :to="{ path: `/aws/instances/${data.item.InstanceId}`}">
                                <i class="uil uil-eye font-size-18"></i>
                            </router-link>
                        </li>
                        <li class="list-inline-item">
                            <a href="#" class="link-danger" @click="deregisterInstanceTargetGroup(data.item)"
                               :class="{ 'disabled': data.item.AllowInstanceActions == 0 || (minRegisteredInstances >= totalRows) }"
                            >
                                <i class="uil uil-desktop-alt-slash font-size-18" title="Deregister Instance"></i>
                            </a>
                        </li>
                    </ul>
                </template>

                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>

            </b-table>
        </div>
        <div class="row">
            <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                                v-model="currentPage"
                                :total-rows="totalRows"
                                :per-page="perPage"
                        ></b-pagination>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
