<script>
  import {required} from "vuelidate/lib/validators";
import Aws from '@/services/Aws';
import validationMessages from '@/components/validations'

export default {
  components:{
    validationMessages
  },
  props: ['eligibleInstances', 'registeredInstances', 'targetGroupName'],
  data() {
    return {
      csrf_token: localStorage.getItem('csrf_token'),
      submitted: false,
      showModal: false,
      tryingToEdit: false,
      showLoader:false,
      modalTitle:'',
      instance: '',
      filteredEligibleInstances:[]
    };
  },
  validations: {
    instance: {
      required,
    }
  },
  methods: {
    async registerInstanceToTargetGroup(){
      this.tryingToEdit = true;
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.tryingToEdit = false;
        return;
      } else {
        try {
          await Aws.registerInstanceToTargetGroup({
            InstanceId: this.instance,
            TargetGroupArn: this.$route.params.id,
            csrf_token: this.csrf_token
          }).then((response) => {
            this.successmsg("Changes has been saved");
            this.closeModal();
          }).catch(error => {
            this.error = error.response.data.error ? error.response.data.error : "";
            this.failedmsg(this.error);
          }).finally(() => {
            this.refreshData();
            this.tryingToEdit = false;
          });
        } catch (error) {
          this.error = error.response.data.error ? error.response.data.error : "";
          this.failedmsg(this.error);
        }
      }

      this.tryingToEdit = false;
    },

    filterEligibleInstances() {
      // Extract the InstanceIds from registeredInstances into a Set for fast lookups
      const registeredInstanceIds = new Set(this.registeredInstances.map(instance => instance.InstanceId));

      // Filter eligibleInstances to exclude those already present in registeredInstanceIds
      this.filteredEligibleInstances = this.eligibleInstances.filter(instance => !registeredInstanceIds.has(instance.InstanceId));
    },

    refreshData() {
      this.$emit('onRefresh')
    },

    closeModal() {
      this.showModal = false;
    },

    initialData(){
      this.filterEligibleInstances()
    }

  }
};
</script>

<template>
  <b-modal @shown="initialData" v-model="showModal" id="registerInstanceToTargetGroup_edit" title="Register Instance to Target Group" title-class="font-18" centered>
    <form @submit.prevent="registerInstanceToTargetGroup">
      <div class="row">
        <div class="col-12 text-center">
          <h5>Target Group Name</h5>
          <h5 class="text-strong">{{targetGroupName}}</h5>
        </div>
        <div class="col-sm-12 col-md-12">
          <label class="control-label form-label">Choose Instance to Register</label>
          <b-form-select
                  class="form-control"
                  aria-describedby="Eligible-instances"
                  v-model="instance"
                  :class="{
                      'is-invalid': submitted && $v.instance.$error,
                    }"
          >
            <option v-for="instance in filteredEligibleInstances" :key="instance.InstanceId" :value="instance.InstanceId">
              {{ instance.InstanceName }}
            </option>
          </b-form-select>
          <validationMessages v-if="submitted" :fieldName="'Eligible Instance'" :validationName="$v.instance"></validationMessages>
        </div>

        <div class="col-12 text-center text-danger" v-if="!filteredEligibleInstances.length">
          <p>No Eligible Instances to register to target group</p>
        </div>
      </div>

    </form>
    <template v-slot:modal-footer>
      <b-button variant="light" @click="closeModal">Close</b-button>
      <b-button variant="primary" @click="registerInstanceToTargetGroup" :disabled="tryingToEdit || !filteredEligibleInstances.length">
        <b-spinner v-show="tryingToEdit" small></b-spinner>
        Register</b-button>
    </template>
  </b-modal>
</template>